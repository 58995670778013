import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { SdirFooter, SdirHeader, styles } from '@sdir/sds';
import { DEFAULT_LANG, getLanguageOptions } from '../../../helpers/languageHelpers';

type AppLayoutProps = {
  children: any;
  onChangeLanguage: (l: string) => void;
};

const AppLayout: React.FC<AppLayoutProps> = ({ children, onChangeLanguage }) => {
  const intl = useIntl();

  const languageOptions = useMemo(() => {
    return getLanguageOptions();
  }, []);

  return (
    <>
      <SdirHeader
        appName={intl.formatMessage({ id: 'home.page.title' })}
        backLink={{
          url: 'https://sdir.no',
          text: intl.formatMessage({ id: 'userbar.sdirlink' }),
        }}
        maxContentWidth="1490px"
        languageOptions={languageOptions}
        onChangeLanguage={onChangeLanguage}
        selectedLanguage={intl.locale || DEFAULT_LANG}
      />
      <AppContainer data-testid="verify-app-container">{children}</AppContainer>
      <SdirFooter
        tagline={intl.formatMessage({ id: 'footer.tagline' })}
        maxContentWidth="1490px"
        languageOptions={languageOptions}
        onChangeLanguage={onChangeLanguage}
        selectedLanguage={intl.locale || DEFAULT_LANG}
        socialHeader={intl.formatMessage({ id: 'footer.social.header' })}
        socialLinks={[
          {
            text: intl.formatMessage({ id: 'footer.social.facebook' }),
            type: 'facebook',
            url: 'https://www.facebook.com/sjofartsdir',
          },
          {
            text: intl.formatMessage({ id: 'footer.social.instagram' }),
            type: 'instagram',
            url: 'https://www.instagram.com/sjofartsdir/',
          },
          {
            text: intl.formatMessage({ id: 'footer.social.linkedin' }),
            type: 'linkedin',
            url: 'https://no.linkedin.com/company/sjofartsdir',
          },
        ]}
      />
    </>
  );
};

export default AppLayout;

const AppContainer = styled.div`
  background-color: ${styles.colors.background};
  margin: 0 auto 0 auto;
  max-width: 1490px;
  min-height: 700px;
  padding: 20px 0;

  @media screen and (max-width: ${({ theme }) => theme.screen.xLarge}) {
    padding: 0 15px 15px 15px;
  }

  footer ul {
    padding-left: 0;
  }
`;
