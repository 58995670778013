import React from 'react';
import { Switch, HashRouter, Route } from 'react-router-dom';
import { StandardRoutes } from '@sdir/auth/lib/components';
import CertificatePage from './Pages/CertificatePage/CertificatePage';
import SearchForm from './Organisms';
import { DEFAULT_LANG } from '../helpers/languageHelpers';
import { routes } from '../constants/routes';

type Props = {
  currentLanguage: string;
};

const Routes: React.FC<Props> = ({ currentLanguage = DEFAULT_LANG }) => {
  const languagePrepend = currentLanguage === DEFAULT_LANG ? '' : `/${currentLanguage}`;
  return (
    <HashRouter>
      <Switch>
        <Route exact path={`${languagePrepend}${routes.home}`} component={SearchForm} />
        <Route
          exact
          path={`${languagePrepend}${routes.certificateSearch}`}
          component={SearchForm}
        />
        <Route
          exact
          path={`${languagePrepend}${routes.certificateByUid}`}
          component={CertificatePage}
        />
        <StandardRoutes />
      </Switch>
    </HashRouter>
  );
};

export default Routes;
