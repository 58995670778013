import { DEFAULT_LANG } from '../helpers/languageHelpers';

export const routes = {
  home: '/',
  certificateSearch: '/certificate',
  certificateByUid: '/certificate/:uid',
};

export const newLanguageRoute = (currentLang: string, newLang: string) => {
  let newUrl = window.location.href || '';
  const hasHash = !!(newUrl.indexOf('/#/') !== -1);
  if (newUrl.indexOf(`/${currentLang}/`) !== -1) {
    if (newLang === DEFAULT_LANG) {
      newUrl = newUrl.replace(`/${currentLang}/`, '/');
    } else {
      newUrl = newUrl.replace(`/${currentLang}/`, `/${newLang}/`);
    }
  } else {
    newUrl = hasHash ? newUrl.replace('/#/', `/#/${newLang}/`) : `${newUrl}${newLang}/`;
  }
  return newUrl;
};
