import { RadioOption } from '@sdir/sds';
import { messages } from '../localization';

export const DEFAULT_LANG = 'nb';

export const getLanguageOptions = () => {
  const options: RadioOption[] = [];
  Object.keys(messages).forEach((key) => {
    if (messages[key]?.language) {
      options.push({ label: messages[key]?.language, value: key });
    }
  });
  return options;
};

export const getCurrentLanguageOutsideProvider = () => {
  const languageOptions = getLanguageOptions();
  const urlSegments = window.location.href?.split('/');
  let langCode = DEFAULT_LANG;
  urlSegments?.forEach((u) => {
    const match = languageOptions.find((o) => o.value === u);
    if (match) langCode = match?.value;
  });
  return langCode;
};
