import React from 'react';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { styles } from '@sdir/sds';
import { NumberServiceProvider } from '@sdir/utilities/lib/providers/';
import { SdirAuthProvider, SdirAuthProviderProps } from '@sdir/auth/lib/providers/SdirAuthProvider';
import { messages } from '../../localization';
import { DEFAULT_LANG } from '../../helpers/languageHelpers';

type Props = {
  children: any;
  currentLanguage: string;
};

const AppProviders: React.FC<Props> = ({ children, currentLanguage = DEFAULT_LANG }) => {
  const authSettings: SdirAuthProviderProps = {
    authenticationSettings: {
      client_id: process.env.REACT_APP_CLIENT_ID as string,
      client_secret: process.env.REACT_APP_CLIENT_SECRET as string,
      scope: process.env.REACT_APP_SCOPE as string,
      extraQueryParams: { securitylevel: '4' },
    },
  };

  return (
    <SdirAuthProvider {...authSettings}>
      <IntlProvider locale={currentLanguage} messages={messages[currentLanguage]}>
        <NumberServiceProvider>
          <ThemeProvider theme={styles}>{children}</ThemeProvider>
        </NumberServiceProvider>
      </IntlProvider>
    </SdirAuthProvider>
  );
};

export default AppProviders;
