import React, { useCallback, useEffect, useState } from 'react';
import { AppVersionCheck } from '@sdir/utilities/lib/components';
import AppProviders from './components/Templates/AppProviders';
import Routes from './components/Routes';
import AppLayout from './components/Organisms/AppLayout/AppLayout';
import { DEFAULT_LANG, getCurrentLanguageOutsideProvider } from './helpers/languageHelpers';
import { newLanguageRoute } from './constants/routes';

const baseUrl = process.env.REACT_APP_VERCHECK_URL || window.location.href.split('#')[0];
const versionCheckInterval = process.env.REACT_APP_VERCHECK_INTERVAL
  ? +process.env.REACT_APP_VERCHECK_INTERVAL
  : null;

const App = () => {
  const [language, setLanguage] = useState<string>(DEFAULT_LANG);

  const onChangeLanguage = useCallback(
    (newLang) => {
      setLanguage(newLang);
      const newUrl = newLanguageRoute(language, newLang);
      window.location.href = newUrl;
    },
    [language]
  );

  useEffect(() => {
    const langFromUrl = getCurrentLanguageOutsideProvider();
    setLanguage(langFromUrl || DEFAULT_LANG);
  }, []);

  return (
    <AppProviders currentLanguage={language}>
      <AppLayout onChangeLanguage={onChangeLanguage}>
        {versionCheckInterval && (
          <AppVersionCheck baseUrl={baseUrl} checkIntervalMs={versionCheckInterval} />
        )}
        <Routes currentLanguage={language} />
      </AppLayout>
    </AppProviders>
  );
};

export default App;
