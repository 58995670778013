import { SdirButton } from '@sdir/sds';
import { Field, Form, Formik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import styled from 'styled-components';
import { ErrorMessage } from '@sdir/utilities/lib/components';
import { FormInput } from '../../Molecules';
import CertificateView, { CertificateViewProps } from '../../Molecules/CertificateView';
import { personalCertificateVerifyApi } from '../../../httpclient';
import { ConvertCertificate } from '../../../helpers/ConvertCertificate';

type InitialValues = {
  birthNr: string;
  certNr: string;
};

const SearchForm: React.FC = () => {
  const fm = useIntl().formatMessage;
  const [certificate, setCertificate] = useState<null | CertificateViewProps>();
  const [searchError, setSearchError] = useState<any>();

  const initialValues: InitialValues = {
    birthNr: '',
    certNr: '',
  };

  const handleSubmit = useCallback(
    (values: InitialValues) => {
      personalCertificateVerifyApi
        .v1PersonalcertificateverifyVerifybynumberandidentityDataPost({
          certificateNumber: +values.certNr,
          personalIdentityNumber: values.birthNr,
        })
        .then((response) => {
          if (response.status === 200) {
            setCertificate(ConvertCertificate(response.data));
            setSearchError(undefined);
          }
        })
        .catch((error) => {
          const { status } = error?.response || {};
          if (status >= 400 && status < 500) {
            setSearchError(fm({ id: 'certificate.notfound' }));
          } else {
            setSearchError(error);
          }
        });
    },
    [fm]
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      birthNr: Yup.string()
        .matches(/^[0-9]+$/, fm({ id: 'birthnumber.field.only.digits' }))
        .min(11, fm({ id: 'birthnumber.field.must.have.length' }))
        .max(11, fm({ id: 'birthnumber.field.must.have.length' }))
        .required(fm({ id: 'required.field' })),
      certNr: Yup.string()
        .matches(/^[0-9]+$/, fm({ id: 'certificate.number.only.digits' }))
        .min(4, fm({ id: 'certificate.number.length' }))
        .max(10, fm({ id: 'certificate.number.length' }))
        .test('int32', fm({ id: 'certificate.number.toobig' }), (v?: string) => {
          if (!v) return true;
          const nr = parseInt(v, 10);
          if (typeof nr === 'number') {
            return nr <= 2147483647;
          }
          return true;
        })
        .required(fm({ id: 'required.field' })),
    });
  }, [fm]);

  return (
    <>
      <h1>{fm({ id: 'certificate.search.header' })}</h1>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {() => (
          <StyledForm>
            <Field label={fm({ id: 'birthnumber' })} name="birthNr" component={FormInput} />
            <Field label={fm({ id: 'certificate.number' })} name="certNr" component={FormInput} />
            <StyledSdirButton htmlType="submit" type="primary" text={fm({ id: 'search' })} />
            {searchError && <ErrorMessage error={searchError} />}
          </StyledForm>
        )}
      </Formik>
      {certificate && !searchError && <CertificateView {...certificate} />}
    </>
  );
};

const StyledForm = styled(Form)`
  display: flex;
  grid-row-gap: 1em;
  flex-direction: column;
`;

const StyledSdirButton = styled(SdirButton)`
  width: fit-content;
`;

export default SearchForm;
