import {
  CertificateStatus,
  LanguageComponent,
} from '@sdir/httpclient/lib/clients/aps/personalcertificate';
import { SdiCheck, SdiWarning, SdiClose, SdirLogo, styles } from '@sdir/sds';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import CardRow from '../Atoms/CardRow';
import { DEFAULT_LANG } from '../../helpers/languageHelpers';

export interface CertificateViewProps {
  certNr?: string;
  certificateType?: LanguageComponent;
  certificateName?: LanguageComponent;
  certificateStatus?: CertificateStatus;
  certCode?: string;
  personName: string;
  validFrom?: string;
  issuedDate?: string;
  expiryDate: string;
}

const CertificateView: React.FC<CertificateViewProps> = ({
  certNr,
  certificateName,
  certificateStatus,
  personName,
  validFrom,
  issuedDate,
  expiryDate,
}) => {
  const intl = useIntl();
  const fm = intl.formatMessage;

  const getTextByKey = useCallback(
    (val?: LanguageComponent) => {
      if (val) {
        const text = intl.locale === DEFAULT_LANG ? val.no : val.en;
        return text;
      }
      return '';
    },
    [intl.locale]
  );

  const { statusText, statusIcon, statusColor } = useMemo(() => {
    let color = styles.colors.alert.errorHot;
    let icon = <SdiClose color={styles.colors.alert.errorHot} />;
    if (certificateStatus === CertificateStatus.Valid) {
      icon = <SdiCheck color={styles.colors.alert.successDarker} />;
      color = styles.colors.alert.successDarker;
    } else if (certificateStatus === CertificateStatus.Inactive) {
      icon = <SdiWarning color={styles.colors.alert.warningDark} />;
      color = styles.colors.alert.warningDark;
    }
    return {
      statusText: fm({ id: `certificate.status.${certificateStatus}` }),
      statusIcon: icon,
      statusColor: color,
    };
  }, [certificateStatus, fm]);

  return (
    <Wrapper>
      <Card>
        <CardHeader textColor={statusColor}>
          <SdirLogo height="48px" />
          {statusIcon}
          <span>{statusText}</span>
        </CardHeader>
        <CardRow label={fm({ id: 'certificate.overview.cert.name' })}>
          <CertName>{getTextByKey(certificateName)}</CertName>
        </CardRow>
        {certNr && <CardRow label={fm({ id: 'certificate.overview.number' })}>{certNr}</CardRow>}
        <CardRow label={fm({ id: 'certificate.overview.name' })}>{personName}</CardRow>
        <CardRow label={fm({ id: 'certificate.overview.issued.date' })}>
          {issuedDate ? moment(issuedDate).format('DD.MM.YYYY') : '-'}
        </CardRow>
        <CardRow label={fm({ id: 'certificate.overview.validfrom.date' })}>
          {validFrom ? moment(validFrom).format('DD.MM.YYYY') : '-'}
        </CardRow>
        <CardRow label={fm({ id: 'certificate.overview.expiry.date' })}>
          {moment(expiryDate).format('DD.MM.YYYY')}
        </CardRow>
      </Card>
    </Wrapper>
  );
};

const CardHeader = styled.div<{ textColor: string }>`
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
  flex-direction: column;
  text-align: center;
  grid-gap: 1em;
  color: ${({ textColor }) => textColor};

  img {
    max-width: 100%;
  }
`;

const Card = styled.div`
  display: grid;
  width: 300px;
  border-radius: 20px;
  background-color: ${styles.colors.background.secondary};
  border-radius: ${styles.card.borderRadius};
  box-shadow: ${styles.card.boxShadow};
  padding: 1em;
  margin: 1em 0;

  @media screen and (max-width: ${({ theme }) => theme.screen.medium}) {
    margin: 1em auto 1em 0;
    width: 100%;
    max-width: 420px;
  }
`;

const CertName = styled.div`
  &:first-letter {
    text-transform: uppercase;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default CertificateView;
